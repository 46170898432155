export const defaultTheme = {
  extend: {
    fontFamily: {
      sans: ['Mulish', 'Helvetiva', 'Arial', 'sans-serif'],
    },
    fontSize: {
      tiny: '0.75rem',
      small: '0.875rem',
      normal: '1rem',
      large: '1.25rem',
      xlarge: '1.5rem',
    },
    colors: {
      'wl-primary': '#068075',
      'wl-accent': '#00BA82',
      'wl-dark': '#072D50',
      'wl-light': '#ECF7FF',
      'wl-select': '#B0C9DC',
      'wl-white': '#FFFFFF',
      'wl-light-grey': '#E5EAEE',
      'wl-grey': '#BDC6CC',
      'wl-dark-grey': '#737F87',
      'wl-black': '#031322',
      'wl-red': '#B41700',
      'wl-orange': '#FF9C1A',
      'wl-yellow': '#FFC700',
      'wl-green': '#41754C',
      'wl-data-blue': '#55B6D0',
      'wl-data-green': '#55D05D',
    },
  },
};
