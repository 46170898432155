export const mantaTheme = {
  name: 'manta-theme',
  extend: {
    colors: {
      'wl-primary': '#14A9E6',
      'wl-accent': '#00205B',
      'wl-dark': '#00205B',
      'wl-light': '#ECF7FF',
      'wl-select': '#B0C9DC',
      'wl-white': '#FFFFFF',
      'light-grey': '#E5EAEE',
      'wl-grey': '#BDC6CC',
      'wl-dark-grey': '#737F87',
      'wl-black': '#031322',
      'wl-red': '#B41700',
      'wl-orange': '#FF9C1A',
      'wl-yellow': '#FFC700',
      'wl-green': '#41754C',
      'wl-data-blue': '#55B6D0',
      'wl-data-green': '#55D05D',
    },
  },
};
