import { mantaTheme } from './manta-theme';
import { defaultTheme } from './default-theme';

export const theme = (branding: 'manta' | 'virtamed') =>
  branding === 'manta' ? mantaTheme : defaultTheme;

export const colors = (branding: 'manta' | 'virtamed') =>
  theme(branding).extend.colors;

export const colorNames = Object.keys(defaultTheme.extend.colors);

export type ColorName = (typeof colorNames)[number];
